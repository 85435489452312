import { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  List,
  ThemeIcon,
  rem,
  TextInput,
  Flex,
  LoadingOverlay,
  Loader,
  Title,
  Checkbox,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import axios from "axios";
import { logEvent } from "../logEvent";
import { makeApiUrl } from "../makeUrl";
import { useAppContext } from "../AppContext";

export default function HintErrorModal() {
  const {
    closeHintErrorModal,
    hintErrorModalOpened,
    openLoginModal,
    openRegisterModal,
    hint,
    setHint,
    openSuccessModal,
    selectedLocations,
    selectedLocationIndex,
    openNextLocationModal,
    setActive,
    setPlace,
    loginMode,
    numRequiredLocations,
    closeAllModals,
    openWelcomeModal,
    setSelectedLocationIndex,
    setSelectedLocations,
    hintError,
    setSuggestion,
    suggestion,
  }: any = useAppContext();
  const [visible, { open, close }] = useDisclosure(false);
  const go = () => {
    closeAllModals();
    setActive(selectedLocations.length);
    setHint("");
    setPlace("");
    if (selectedLocations.length === numRequiredLocations) {
      if (loginMode) {
        openLoginModal();
      } else {
        openRegisterModal();
      }
    } else {
      setSelectedLocationIndex(selectedLocations.length);
      selectedLocations[selectedLocations.length] = {
        lat: 0,
        lng: 0,
        confirmed: false,
        hint: "",
      };
      setSelectedLocations([...selectedLocations]);
      openWelcomeModal();
    }
  };

  const inputRef: any = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [hintErrorModalOpened]);
  return (
    <>
      <Modal
        xOffset="1vh"
        opened={hintErrorModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Hint modal was closed by onBlur or other",
          });
          closeHintErrorModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{ children: <Loader color="blue" /> }}
        />
        {/* <Text mb="xl">The hint must:</Text>
        <List
          mb="xl"
          spacing="xs"
          size="sm"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
        >
          <List.Item>
            Provide enough information for you to remember it later
          </List.Item>
          <List.Item>Not reveal the location</List.Item>
        </List> */}
        <Title mb="xs" order={3}>
          Our AI detected an issue with your hint
        </Title>
        <Text mb="xs">Potential problem: {hintError}</Text>
        <Text mb="xs">Suggestion: {suggestion}</Text>
        <Flex direction="row" justify="right">
          <Button
            mt="md"
            ml="md"
            onClick={() => {
              closeHintErrorModal();
            }}
            disabled={visible || !hint}
          >
            Go back
          </Button>
          {hintError === "unintelligible input" && (
            <Button
              mt="md"
              ml="md"
              onClick={() => {
                selectedLocations[selectedLocationIndex].hint = hint;
                go();
              }}
              disabled={visible || !hint}
              color="red"
            >
              Continue anyway
            </Button>
          )}
          {suggestion !== "" && hintError !== "unintelligible input" && (
            <Button
              mt="md"
              ml="md"
              onClick={() => {
                setHint(suggestion);
                selectedLocations[selectedLocationIndex].hint = suggestion;
                go();
              }}
              disabled={visible || !hint}
              color="green"
            >
              Accept suggestion
            </Button>
          )}
        </Flex>
      </Modal>
    </>
  );
}
