import { CodeHighlight, CodeHighlightTabs } from "@mantine/code-highlight";
import { Modal, Table, Tabs, Text, Title } from "@mantine/core";
import { Map } from "centeridentityreact";
import { useAppContext } from "../AppContext";

export default function SDKModal() {
  const { sdkModalOpened, closeSdkModal }: any = useAppContext();
  const code = `
const [selectedLocations, setSelectedLocations] = useState<
  Array<{ lat: number; lng: number; confirmed: boolean; hint?: string }>
>([
  {
    lat: 0,
    lng: 0,
    confirmed: false,
    hint: "",
  },
]);
const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>(0);

const setSelectedLocationHandler = (location: { lat: number; lng: number; confirmed: boolean; }) => {
  // Prompt user for hint.
  location.hint = "some hint from user prompt";
  selectedLocations[selectedLocationIndex] = location;
  setSelectedLocations([...selectedLocations]);
  setSelectedLocationIndex(selectedLocations.length - 1)
}

<Map
  setSelectedLocation={setSelectedLocationHandler}
  mapContainerStyle={{
    width: "100%",
    height: "100%",
  }}
/>
  `;
  const data = [
    {
      name: "selectedLocation",
      type: `
{
  lat: number;
  lng: number;
}`,
      description:
        "This is the currently selected geocoordinate rounded to the precision prop.",
    },
    {
      name: "setSelectedLocation",
      type: `
(location: {
  lat: number;
  lng: number;
  confirmed: boolean;
  hint: string;
}) => void;`,
      description: "Event hook for when a user selects a location.",
    },
    {
      name: "onZoomWithGrid",
      type: `(zoomLevel: number) => void;`,
      description:
        "Event hook for when a user changes the zoom of the map when selection grid is not visible.",
    },
    {
      name: "onZoomWithoutGrid",
      type: `(zoomLevel: number) => void;`,
      description:
        "Event hook for when a user changes the zoom of the map when selection grid is visible.",
    },
    {
      name: "onGridSquareClicked",
      type: `(squareId: string) => void;`,
      description:
        "Event hook to set the grid square ID when the user selects a grid square.",
    },
    {
      name: "selectedSquareId",
      type: `string;`,
      description: "The concatenated coordinates for the selected square.",
    },
    {
      name: "center",
      type: `
{
  lat: number;
  lng: number;
}`,
      description: "Sets the coordinates of where the map view is centered.",
    },
    {
      name: "zoom",
      type: `number;`,
      description: "Sets the zoom level of the map view.",
    },
    {
      name: "getMap",
      type: `(map: google.maps.Map) => void;`,
      description: "Hook to get the map object for further customization.",
    },
    {
      name: "mapContainerStyle",
      type: `
{
  width: string | number;
  height: string | number;
};`,
      description: "Hook to get the map object for further customization.",
    },
  ];
  const rows = data.map((item) => {
    return (
      <Table.Tr key={item.name}>
        <Table.Td>{item.name}</Table.Td>
        <Table.Td>
          <CodeHighlight code={item.type} language="tsx" />
        </Table.Td>
        <Table.Td style={{ minWidth: 400 }}>{item.description}</Table.Td>
      </Table.Tr>
    );
  });

  const keyPairCode = `
JSON.parse(JSON.stringify(selectedLocations)).sort(
  (a: any, b: any) => {
    if (a.lat > b.lat) {
      return 1;
    }
    if (a.lat < b.lat) {
      return -1;
    }
    if (a.lat === b.lat) {
      if (a.lng > b.lng) {
        return 1;
      }
      if (a.lng < b.lng) {
        return -1;
      }
      return 0;
    }
  }
);
const entropy =
  selectedLocations[0].lat.toFixed(4) +
  selectedLocations[0].lng.toFixed(4) +
  selectedLocations[1].lat.toFixed(4) +
  selectedLocations[1].lng.toFixed(4) +
  selectedLocations[2].lat.toFixed(4) +
  selectedLocations[2].lng.toFixed(4);`;

  return (
    <Modal opened={sdkModalOpened} onClose={closeSdkModal} size="100%">
      <Title mb="md">SDK Documentation</Title>
      <Tabs defaultValue="documentation">
        <Tabs.List mb="md">
          <Tabs.Tab value="documentation">Documentation</Tabs.Tab>
          <Tabs.Tab value="props">Props</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="documentation">
          <Title order={2} mb="xs">
            Usage
          </Title>
          <Map mapContainerStyle={{ width: "100%", height: "200px" }} />
          <Title order={2} mb="xs" mt="md">
            Install
          </Title>
          <CodeHighlight
            code={`yarn add centeridentityreact`}
            language="bash"
          />
          <Title order={2} mb="xs" mt="md">
            Import
          </Title>
          <CodeHighlight
            code={`import { Map } from "centeridentityreact";`}
            language="tsx"
          />
          <Title order={2} mb="xs" mt="md">
            Example
          </Title>
          <CodeHighlight code={code} language="tsx" />
          <Title order={2} mb="xs" mt="md">
            Generate A Key Pair
          </Title>
          <Text mb="xs" mt="md">
            Simply sort and concatenate the locations into a string and this
            becomes the entropy for your private key derivation function, such
            as pbkdf2.
          </Text>
          <CodeHighlight code={keyPairCode} language="tsx" />
        </Tabs.Panel>
        <Tabs.Panel
          value="props"
          style={{ overflowX: "scroll", maxWidth: "100%" }}
        >
          <Title order={2} mb="md">
            Props
          </Title>
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Type</Table.Th>
                <Table.Th>Description</Table.Th>
              </Table.Tr>
              {rows}
            </Table.Thead>
          </Table>
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
}
