import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  Title,
  List,
  ThemeIcon,
  rem,
  TextInput,
  PasswordInput,
  Flex,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { hideAllNotifications, showNotification } from "../showNotification";
import { useAppContext } from "../AppContext";

export default function ConfirmModal() {
  const {
    closeConfirmModal,
    openSuccessModal,
    confirmModalOpened,
    closeHintModal,
    openHintModal,
    hintModalOpened,
    selectedLocations,
    map,
    setConfirmMode,
    setSelectedSquareId,
    selectedLocationIndex,
    setSelectedLocations,
  }: any = useAppContext();

  return (
    <>
      <Modal
        opened={confirmModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Confirm modal was closed by onBlur or other",
          });
          closeConfirmModal();
          selectedLocations[selectedLocationIndex] = {
            lat: 0,
            lng: 0,
            confirmed: false,
            hint: "",
          };
          setSelectedLocations([...selectedLocations]);
        }}
        title="Confirm selection"
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <Text>
          You must confirm your selection by finding it on the map again.
        </Text>
        <Text>
          If you need to select a different location, click the 'try again'
          button.
        </Text>
        <Flex direction="row" justify="space-between">
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Try again button in confirm modal",
              });
              closeConfirmModal();
              selectedLocations[selectedLocationIndex] = {
                lat: 0,
                lng: 0,
                confirmed: false,
                hint: "",
              };
              setSelectedLocations([...selectedLocations]);
            }}
          >
            Try again
          </Button>
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Confirm selection button in confirm modal",
              });
              setSelectedSquareId("-");
              closeConfirmModal();
              setConfirmMode(true);
              setTimeout(() => {
                hideAllNotifications();
                showNotification({
                  title: "Confirm your location",
                  message:
                    "Confirm your secret location. Zoom in and select it again.",
                  color: "blue",
                });
              }, 750);
            }}
          >
            Confirm selection
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
