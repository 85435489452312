import {
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  useMantineTheme,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCode,
  IconCoin,
  IconChevronDown,
  IconWallet,
  IconPasswordUser,
  IconAmbulance,
  IconAuth2fa,
  IconApi,
} from "@tabler/icons-react";
import classes from "./HeaderMegaMenu.module.scss";
import { makeApiUrl, makeUrl } from "../makeUrl";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { showNotification } from "../showNotification";
import { useAppContext } from "../AppContext";

export function HeaderMegaMenu() {
  const {
    toggleDrawer,
    closeDrawer,
    drawerOpened,
    openTutorial,
    openLoginModeSwitchModal,
    loginMode,
    setLoginMode,
    selectedLocations,
    openSdkModal,
    openApiModal,
    closeAllModals,
    businessLinksOpened,
    developerLinksOpened,
    individualLinksOpened,
    toggleIndividualLinks,
    toggleDeveloperLinks,
    toggleBusinessLinks,
  }: any = useAppContext();

  const individual = [
    {
      icon: IconWallet,
      title: "Digital Wallet",
      description: "Use secret locations to generate a digital wallet.",
      link: "/digital-wallet",
    },
    {
      icon: IconPasswordUser,
      title: "Password Manager",
      description:
        "Your secret locations can be the root of all your passwords.",
      link: "/password-management",
    },
  ];
  const business = [
    {
      icon: IconAmbulance,
      title: "Account Recovery",
      description:
        "Easily memorable secret locations make account recovery simple and more secure.",
      link: "/account-recovery",
    },
    {
      icon: IconAuth2fa,
      title: "Workforce Authentication",
      description:
        "Using secret locations for workforce authentication reduces your risk of data breach.",
      link: "/authentication",
    },
  ];
  const developer = [
    {
      icon: IconCode,
      title: "SDK",
      description:
        "We have complete documentationt to help you implement our solution.",
      onClick: () => {
        closeAllModals();
        openSdkModal();
      },
    },
    {
      icon: IconApi,
      title: "API",
      description:
        "Use our api to check hints for security questions and secret locations.",
      onClick: () => {
        closeAllModals();
        openApiModal();
      },
    },
  ];
  const theme = useMantineTheme();

  const getLink = (item: any) => {
    return (
      <Anchor href={makeUrl(item.link)} key={item.title}>
        <UnstyledButton className={classes.subLink} style={{ padding: 10 }}>
          <Group wrap="nowrap" align="flex-start">
            <ThemeIcon size={34} variant="default" radius="md">
              <item.icon
                style={{ width: rem(22), height: rem(22) }}
                color={theme.colors.blue[6]}
              />
            </ThemeIcon>
            <div>
              <Text size="sm" fw={500}>
                {item.title}
              </Text>
              <Text size="xs" c="dimmed">
                {item.description}
              </Text>
            </div>
          </Group>
        </UnstyledButton>
      </Anchor>
    );
  };
  const getButton = (item: any) => {
    return (
      <UnstyledButton
        key={item.title}
        className={classes.subLink}
        style={{ padding: 10 }}
        onClick={() => {
          closeDrawer();
          item.onClick();
          logEvent("click", {
            target: `Open ${item.title} button in desktop header`,
          });
        }}
      >
        <Group wrap="nowrap" align="flex-start">
          <ThemeIcon size={34} variant="default" radius="md">
            <item.icon
              style={{ width: rem(22), height: rem(22) }}
              color={theme.colors.blue[6]}
            />
          </ThemeIcon>
          <div>
            <Text size="sm" fw={500}>
              {item.title}
            </Text>
            <Text size="xs" c="dimmed">
              {item.description}
            </Text>
          </div>
        </Group>
      </UnstyledButton>
    );
  };
  const businessLinks = business.map((item) => {
    return getLink(item);
  });
  const individualLinks = individual.map((item) => {
    return getLink(item);
  });
  const developerLinks = developer.map((item) => {
    return getButton(item);
  });

  const duration = 0;
  const openDelay = 0;
  const closeDelay = 0;
  const offset = 0;
  let showLocation = false;
  if (
    selectedLocations.length > 1 ||
    (selectedLocations[0].lat !== 0 && selectedLocations[0].lng !== 0)
  ) {
    showLocation = true;
  }
  return (
    <Box pb={0}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          <Image
            src="https://centeridentity.com/centeridentitystatic/center-identity-logo.png"
            height={45}
          />

          <Group h="100%" gap={0} visibleFrom="sm">
            <a
              href="#"
              className={classes.link}
              onClick={() => {
                logEvent("click", {
                  target: "Open tutorial in desktop header",
                });
                openTutorial();
              }}
            >
              Tutorial
            </a>
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
              openDelay={openDelay}
              closeDelay={closeDelay}
              transitionProps={{ duration: duration }}
              offset={offset}
            >
              <HoverCard.Target>
                <a href="#" className={classes.link}>
                  <Center inline>
                    <Box component="span" mr={5}>
                      Business
                    </Box>
                    <IconChevronDown
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.blue[6]}
                    />
                  </Center>
                </a>
              </HoverCard.Target>

              <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                <Group justify="space-between" px="md">
                  <Text fw={500}>Business</Text>
                  {/* <Anchor href="#" fz="xs">
                    View all
                  </Anchor> */}
                </Group>

                <Divider my="sm" />

                <SimpleGrid cols={2} spacing={0}>
                  {businessLinks}
                </SimpleGrid>
                {/* 
                <div className={classes.dropdownFooter}>
                  <Group justify="space-between">
                    <div>
                      <Text fw={500} fz="sm">
                        Get started
                      </Text>
                      <Text size="xs" c="dimmed">
                        Their food sources have decreased, and their numbers
                      </Text>
                    </div>
                    <Button variant="default" onClick={openTutorial}>
                      Get started
                    </Button>
                  </Group>
                </div> */}
              </HoverCard.Dropdown>
            </HoverCard>
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
              openDelay={openDelay}
              closeDelay={closeDelay}
              transitionProps={{ duration: duration }}
              offset={offset}
            >
              <HoverCard.Target>
                <a href="#" className={classes.link}>
                  <Center inline>
                    <Box component="span" mr={5}>
                      Developer
                    </Box>
                    <IconChevronDown
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.blue[6]}
                    />
                  </Center>
                </a>
              </HoverCard.Target>

              <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                <Group justify="space-between" px="md">
                  <Text fw={500}>Developer</Text>
                  {/* <Anchor href="#" fz="xs">
                    View all
                  </Anchor> */}
                </Group>

                <Divider my="sm" />

                <SimpleGrid cols={2} spacing={0}>
                  {developerLinks}
                </SimpleGrid>

                {/* <div className={classes.dropdownFooter}>
                  <Group justify="space-between">
                    <div>
                      <Text fw={500} fz="sm">
                        Get started
                      </Text>
                      <Text size="xs" c="dimmed">
                        Their food sources have decreased, and their numbers
                      </Text>
                    </div>
                    <Button variant="default">Get started</Button>
                  </Group>
                </div> */}
              </HoverCard.Dropdown>
            </HoverCard>
            <HoverCard
              width={600}
              position="bottom"
              radius="md"
              shadow="md"
              withinPortal
              openDelay={openDelay}
              closeDelay={closeDelay}
              transitionProps={{ duration: duration }}
              offset={offset}
            >
              <HoverCard.Target>
                <a href="#" className={classes.link}>
                  <Center inline>
                    <Box component="span" mr={5}>
                      User
                    </Box>
                    <IconChevronDown
                      style={{ width: rem(16), height: rem(16) }}
                      color={theme.colors.blue[6]}
                    />
                  </Center>
                </a>
              </HoverCard.Target>

              <HoverCard.Dropdown style={{ overflow: "hidden" }}>
                <Group justify="space-between" px="md">
                  <Text fw={500}>User</Text>
                  {/* <Anchor href="#" fz="xs">
                    View all
                  </Anchor> */}
                </Group>

                <Divider my="sm" />

                <SimpleGrid cols={2} spacing={0}>
                  {individualLinks}
                </SimpleGrid>

                {/* <div className={classes.dropdownFooter}>
                  <Group justify="space-between">
                    <div>
                      <Text fw={500} fz="sm" onClick={openTutorial}>
                        Get started
                      </Text>
                      <Text size="xs" c="dimmed">
                        Their food sources have decreased, and their numbers
                      </Text>
                    </div>
                    <Button variant="default">Get started</Button>
                  </Group>
                </div> */}
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>

          <Group visibleFrom="sm"></Group>

          <Burger
            opened={drawerOpened}
            onClick={() => {
              logEvent("click", { target: "toggle drawer" });
              toggleDrawer();
            }}
            hiddenFrom="sm"
          />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={() => {
          logEvent("event", {
            target: "Drawer was closed",
          });
          closeDrawer();
        }}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />
          <a
            href="#"
            className={classes.link}
            onClick={() => {
              logEvent("click", { target: "Open tutorial button in drawer" });
              closeDrawer();
              openTutorial();
            }}
          >
            Tutorial
          </a>
          <UnstyledButton
            className={classes.link}
            onClick={() => {
              logEvent("click", { target: "Toggle business links button" });
              toggleBusinessLinks();
            }}
          >
            <Center inline>
              <Box component="span" mr={5}>
                Business
              </Box>
              <IconChevronDown
                style={{ width: rem(16), height: rem(16) }}
                color={theme.colors.blue[6]}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={businessLinksOpened}>{businessLinks}</Collapse>
          <UnstyledButton
            className={classes.link}
            onClick={() => {
              logEvent("click", { target: "Open developer button in drawer" });
              toggleDeveloperLinks();
            }}
          >
            <Center inline>
              <Box component="span" mr={5}>
                Developer
              </Box>
              <IconChevronDown
                style={{ width: rem(16), height: rem(16) }}
                color={theme.colors.blue[6]}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={developerLinksOpened}>{developerLinks}</Collapse>
          <UnstyledButton
            className={classes.link}
            onClick={() => {
              logEvent("click", { target: "Toggle individual links button" });
              toggleIndividualLinks();
            }}
          >
            <Center inline>
              <Box component="span" mr={5}>
                User
              </Box>
              <IconChevronDown
                style={{ width: rem(16), height: rem(16) }}
                color={theme.colors.blue[6]}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={individualLinksOpened}>{individualLinks}</Collapse>

          <Divider my="sm" />

          <Group justify="center" grow pb="xl" px="md"></Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
