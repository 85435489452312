import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  Title,
  List,
  ThemeIcon,
  rem,
  TextInput,
  PasswordInput,
  Flex,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { hideAllNotifications, showNotification } from "../showNotification";
import { useAppContext } from "../AppContext";

export default function IncorrectModal() {
  const {
    closeIncorrectModal,
    openSuccessModal,
    incorrectModalOpened,
    closeHintModal,
    openHintModal,
    hintModalOpened,
    selectedLocations,
    setSelectedLocations,
    selectedLocationIndex,
    map,
    setConfirmMode,
    setSelectedSquareId,
    setActive,
    active,
  }: any = useAppContext();

  return (
    <>
      <Modal
        opened={incorrectModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Incorrect selection modal was closed by onBlur or other",
          });
          closeIncorrectModal();
        }}
        title="Incorrect selection"
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <Text mb="md">You need to select the same location as before.</Text>
        <Text mb="md">
          If you forgot which location you clicked, click the 'Choose new
          location' button.
        </Text>
        <Text mb="md">
          If you want to attempt to confirm the location again, click the 'Try
          again' button.
        </Text>
        <Flex direction="row" justify="space-between">
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target:
                  "Choose different location button in incorrect selection modal",
              });
              selectedLocations[selectedLocationIndex] = {
                lat: 0,
                lng: 0,
                confirm: false,
              };
              setSelectedLocations([...selectedLocations]);
              closeIncorrectModal();
              map.setZoom(3);
              setConfirmMode(false);
            }}
          >
            Choose different location
          </Button>
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Try again button in incorrect selection modal",
              });
              setSelectedSquareId("");
              closeIncorrectModal();
              setConfirmMode(true);
              setTimeout(() => {
                hideAllNotifications();
                showNotification({
                  title: "Confirm your location",
                  message:
                    "Confirm your secret location. Zoom in and select it again.",
                  color: "red",
                });
              }, 750);
            }}
          >
            Try again
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
