import { Modal, Text, Title, Checkbox, Flex, Button } from "@mantine/core";
import { useState } from "react";
import { logEvent } from "../logEvent";
import { useAppContext } from "../AppContext";

export default function SelectLocationTutorial() {
  const { closeTutorial, tutorialOpened }: any = useAppContext();
  const [checked, setChecked] = useState(false);
  if (localStorage.getItem("tutorial") === "1" && checked === false) {
    setChecked(true);
  }
  if (localStorage.getItem("tutorial") === "0" && checked === true) {
    setChecked(false);
  }
  return (
    <>
      <Modal
        opened={tutorialOpened}
        onClose={() => {
          logEvent("event", {
            target: "Tutorial modal was closed by onBlur or other",
            value: checked,
          });
          closeTutorial();
        }}
        title="Help"
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
        zIndex={1000}
      >
        <Title order={3} mb="xs">
          Step-by-step guide
        </Title>
        <Text fw="bold">Step 1:</Text>
        <Text mb="xs">
          Think of a location with deep personal relevance. For instance, your
          favorite place to play as a child.
        </Text>
        <Text fw="bold">Step 2:</Text>
        <Text mb="xs">
          Zoom into the location by clicking or tapping on the location until a
          grid appears.
        </Text>
        <Text fw="bold">Step 3:</Text>
        <Text mb="md">
          Select the grid segment that covers the location of your personal
          memory.
        </Text>
        <Flex direction="row" justify="space-between" align="center">
          <Checkbox
            label="Never show again"
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
            onClick={() => {
              logEvent("click", {
                target: "Checkbox in tutorial modal",
                value: !checked,
              });
              const v = localStorage.getItem("tutorial");
              if (v) {
                if (v === "1") {
                  localStorage.setItem("tutorial", "0");
                } else {
                  localStorage.setItem("tutorial", "1");
                }
              } else {
                localStorage.setItem("tutorial", "1");
              }
            }}
          ></Checkbox>
          <Button
            onClick={() => {
              logEvent("click", {
                target: "Close button in tutorial modal",
              });
              closeTutorial();
            }}
          >
            Close
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
