import { useEffect, useState, useCallback, useRef } from "react";
import "./App.css";
import {
  MantineProvider,
  Stepper,
  Flex,
  Center,
  TextInput,
  Button,
  Paper,
} from "@mantine/core";
import { Map } from "centeridentityreact";
import { CookiesModal } from "./components/CookiesModal";
import SelectLocationTutorial from "./components/SelectLocationTutorial";
import HintModal from "./components/HintModal";
import RegisterModal from "./components/RegisterModal";
import LoginModal from "./components/LoginModal";
import { HeaderMegaMenu } from "./components/HeaderMegaMenu";
import { FooterCentered } from "./components/FooterCentered";
import { useDisclosure } from "@mantine/hooks";
import { Notifications, notifications } from "@mantine/notifications";
import SuccessModal from "./components/SuccessModal";
import ConfirmModal from "./components/ConfirmModal";
import ConfirmLoginModeModal from "./components/ConfirmLoginModeModal";
import NextLocationModal from "./components/NextLocationModal";
import { hideAllNotifications, showNotification } from "./showNotification";
import IncorrectModal from "./components/IncorrectModal";
import useLeavePrompt from "./useLeavePrompt";
import LoginModeSwitchModal from "./components/LoginModeSwitchModal";
import GlobalKeyHandler from "./components/GlobalKeyHandler";
import { numberToOrdinal } from "./numberToOrdinal";
import WelcomeTutorial from "./components/WelcomeModal";
import SearchPlace from "./components/SearchPlace";
import SDKModal from "./components/SDKModal";
import APIModal from "./components/APIModal";
import UseLocationModal from "./components/UseLocationModal";
import AppContext from "./AppContext";
import LoginRegisterChooserModal from "./components/LoginRegisterChooserModal";
import HintErrorModal from "./components/HintErrorModal";
import BusinessSectorChooser from "./components/BusinessSectorChooser";

function App() {
  const [selectedLocations, setSelectedLocations] = useState<
    Array<{ lat: number; lng: number; confirmed: boolean; hint?: string }>
  >([
    {
      lat: 0,
      lng: 0,
      confirmed: false,
      hint: "",
    },
  ]);

  const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>(0);

  const [selectedPlace, setSelectedPlace] = useState({ label: "" });
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(3);
  const [hint, setHint] = useState(
    window.location.hostname === "localhost" ? "" : ""
  );
  const [confirmMode, setConfirmMode] = useState(false);
  const [selectedSquareId, setSelectedSquareId] = useState("");
  const [active, setActive] = useState(0);
  const [loginMode, setLoginMode] = useState(false);
  const [leavingToDash, setLeavingToDash] = useState(false);
  const [numRequiredLocations, setNumRequiredLocations] = useState(1);
  const [place, setPlace] = useState(
    window.location.hostname === "localhost" ? "" : ""
  );
  const [gridVisible, setGridVisible] = useState(false);
  const [instruction, setInstruction] = useState("");

  const [hintError, setHintError] = useState("");
  const [suggestion, setSuggestion] = useState("");

  if (window.location.hostname !== "localhost") {
    useLeavePrompt("Are you sure you want to leave?", leavingToDash);
  }
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);

  const cookiesShown = window.localStorage.getItem("cookies");
  const [
    cookieModalOpened,
    { open: openCookieModal, close: closeCookieModal },
  ] = useDisclosure(false);

  const [
    welcomeModalOpened,
    { open: openWelcomeModal, close: closeWelcomeModal },
  ] = useDisclosure(false);

  const tutorialShown = window.localStorage.getItem("tutorial");
  const [tutorialOpened, { open: openTutorial, close: closeTutorial }] =
    useDisclosure(false);

  const [
    successModalOpened,
    { open: openSuccessModal, close: closeSuccessModal },
  ] = useDisclosure(false);

  const [
    nextLocationModalOpened,
    { open: openNextLocationModal, close: closeNextLocationModal },
  ] = useDisclosure(false);

  const [
    confirmModalOpened,
    { open: openConfirmModal, close: closeConfirmModal },
  ] = useDisclosure(false);

  const [
    confirmLoginModeModalOpened,
    { open: openConfirmLoginModeModal, close: closeConfirmLoginModeModal },
  ] = useDisclosure(false);

  const [hintModalOpened, { open: openHintModal, close: closeHintModal }] =
    useDisclosure(false);

  const [
    hintErrorModalOpened,
    { open: openHintErrorModal, close: closeHintErrorModal },
  ] = useDisclosure(false);

  const [
    loginModeSwitchModalOpened,
    { open: openLoginModeSwitchModal, close: closeLoginModeSwitchModal },
  ] = useDisclosure(false);

  const [loginModalOpened, { open: openLoginModal, close: closeLoginModal }] =
    useDisclosure(false);

  const [
    registerModalOpened,
    { open: openRegisterModal, close: closeRegisterModal },
  ] = useDisclosure(false);

  const [
    incorrectModalOpened,
    { open: openIncorrectModal, close: closeIncorrectModal },
  ] = useDisclosure(false);

  const [sdkModalOpened, { open: openSdkModal, close: closeSdkModal }] =
    useDisclosure(false);

  const [apiModalOpened, { open: openApiModal, close: closeApiModal }] =
    useDisclosure(false);

  const [
    useLocationModalOpened,
    { open: openUseLocationModal, close: closeUseLocationModal },
  ] = useDisclosure(false);

  const [
    loginRegisterChooserModalOpened,
    {
      open: openLoginRegisterChooserModal,
      close: closeLoginRegisterChooserModal,
    },
  ] = useDisclosure(false);

  const [
    businessSectorChooserModalOpened,
    {
      open: openBusinessSectorChooserModal,
      close: closeBusinessSectorChooserModal,
    },
  ] = useDisclosure(false);

  const [businessLinksOpened, { toggle: toggleBusinessLinks }] =
    useDisclosure(false);
  const [individualLinksOpened, { toggle: toggleIndividualLinks }] =
    useDisclosure(false);
  const [developerLinksOpened, { toggle: toggleDeveloperLinks }] =
    useDisclosure(false);

  const placesInputRef: any = useRef(null);

  useEffect(() => {
    if (cookiesShown) {
      openBusinessSectorChooserModal();
    } else {
      openCookieModal();
    }
  }, [cookiesShown]);

  const setSelectedLocation = useCallback(
    (a: { lat: number; lng: number; confirmed: boolean }) => {
      const rangeCheck = 0.005;
      const inputlat = parseFloat(a.lat.toFixed(4));
      const inputlng = parseFloat(a.lng.toFixed(4));

      if (loginMode) {
        closeAllModals();
        openLoginModal();
      }
      if (!loginMode) {
        for (let i = 0; i < selectedLocations.length; i++) {
          const lat =
            parseFloat(selectedLocations[i].lat.toFixed(4)) + rangeCheck;
          const lng =
            parseFloat(selectedLocations[i].lng.toFixed(4)) + rangeCheck;
          const lat2 =
            parseFloat(selectedLocations[i].lat.toFixed(4)) - rangeCheck;
          const lng2 =
            parseFloat(selectedLocations[i].lng.toFixed(4)) - rangeCheck;
          if (
            !confirmMode &&
            i !== selectedLocationIndex &&
            ((parseFloat(Math.abs(inputlat - lat).toFixed(4)) <= rangeCheck &&
              parseFloat(Math.abs(inputlng - lng).toFixed(4)) <= rangeCheck) ||
              (parseFloat(Math.abs(inputlat - lat2).toFixed(4)) <= rangeCheck &&
                parseFloat(Math.abs(inputlng - lng2).toFixed(4)) <=
                  rangeCheck) ||
              (parseFloat(Math.abs(inputlat - lat).toFixed(4)) <= rangeCheck &&
                parseFloat(Math.abs(inputlng - lng2).toFixed(4)) <=
                  rangeCheck) ||
              (parseFloat(Math.abs(inputlat - lat2).toFixed(4)) <= rangeCheck &&
                parseFloat(Math.abs(inputlng - lng).toFixed(4)) <= rangeCheck))
          ) {
            hideAllNotifications();
            showNotification({
              title: "Location is too close",
              message:
                "This location is too close to one of your other locations",
              color: "red",
            });
            return;
          }
        }
      }
      if (
        !loginMode &&
        confirmMode &&
        selectedLocations[selectedLocationIndex].lat.toFixed(4) ===
          a.lat.toFixed(4) &&
        selectedLocations[selectedLocationIndex].lng.toFixed(4) ===
          a.lng.toFixed(4)
      ) {
        selectedLocations[selectedLocationIndex].confirmed = true;
        closeAllModals();
        openHintModal();
        setSelectedSquareId("-");

        return;
      } else if (
        !loginMode &&
        confirmMode &&
        (selectedLocations[selectedLocationIndex].lat.toFixed(4) !==
          a.lat.toFixed(4) ||
          selectedLocations[selectedLocationIndex].lng.toFixed(4) !==
            a.lng.toFixed(4))
      ) {
        openIncorrectModal();
        return;
      }
      if (
        selectedLocations.length === 1 &&
        selectedLocations[0].lat === 0 &&
        selectedLocations[0].lng === 0
      ) {
        selectedLocations[0] = a;
      } else {
        selectedLocations[selectedLocationIndex] = a;
      }
      setSelectedLocations([...selectedLocations]);
      console.log(a);
    },
    [
      selectedLocations,
      selectedLocationIndex,
      confirmMode,
      loginMode,
      placesInputRef,
    ]
  );

  const getMap = (map: any) => {
    setMap(map);
  };
  const steps = [];
  if (numRequiredLocations)
    for (var i = 0; i < numRequiredLocations; i++) {
      steps.push(
        <Stepper.Step allowStepSelect={false} key={`step${i}`}>
          <Center>{instruction}</Center>
        </Stepper.Step>
      );
    }
  const closeAllModals = useCallback(() => {
    closeCookieModal();
    closeWelcomeModal();
    closeTutorial();
    closeSuccessModal();
    closeNextLocationModal();
    closeConfirmModal();
    closeConfirmLoginModeModal();
    closeHintModal();
    closeLoginModeSwitchModal();
    closeLoginModal();
    closeRegisterModal();
    closeIncorrectModal();
    closeSdkModal();
    closeApiModal();
    closeUseLocationModal();
    closeLoginRegisterChooserModal();
    closeBusinessSectorChooserModal();
  }, [
    closeCookieModal,
    closeWelcomeModal,
    closeTutorial,
    closeSuccessModal,
    closeNextLocationModal,
    closeConfirmModal,
    closeConfirmLoginModeModal,
    closeHintModal,
    closeLoginModeSwitchModal,
    closeLoginModal,
    closeRegisterModal,
    closeIncorrectModal,
    closeSdkModal,
    closeApiModal,
    closeUseLocationModal,
    closeLoginRegisterChooserModal,
    closeBusinessSectorChooserModal,
  ]);
  const modalOpened = useCallback(() => {
    return (
      cookieModalOpened ||
      welcomeModalOpened ||
      tutorialOpened ||
      successModalOpened ||
      nextLocationModalOpened ||
      confirmModalOpened ||
      confirmLoginModeModalOpened ||
      hintModalOpened ||
      hintErrorModalOpened ||
      loginModeSwitchModalOpened ||
      loginModalOpened ||
      registerModalOpened ||
      incorrectModalOpened ||
      sdkModalOpened ||
      apiModalOpened ||
      useLocationModalOpened ||
      loginRegisterChooserModalOpened ||
      businessSectorChooserModalOpened
    );
  }, [
    cookieModalOpened,
    welcomeModalOpened,
    tutorialOpened,
    successModalOpened,
    nextLocationModalOpened,
    confirmModalOpened,
    confirmLoginModeModalOpened,
    hintModalOpened,
    hintErrorModalOpened,
    loginModeSwitchModalOpened,
    loginModalOpened,
    registerModalOpened,
    incorrectModalOpened,
    sdkModalOpened,
    apiModalOpened,
    useLocationModalOpened,
    loginRegisterChooserModalOpened,
    businessSectorChooserModalOpened,
  ]);
  const sharedState = {
    selectedLocations,
    setSelectedLocations,
    selectedLocationIndex,
    setSelectedLocationIndex,
    selectedPlace,
    setSelectedPlace,
    center,
    setCenter,
    map,
    setMap,
    zoom,
    setZoom,
    hint,
    setHint,
    hintError,
    setHintError,
    suggestion,
    setSuggestion,
    confirmMode,
    setConfirmMode,
    selectedSquareId,
    setSelectedSquareId,
    active,
    setActive,
    loginMode,
    setLoginMode,
    leavingToDash,
    setLeavingToDash,
    numRequiredLocations,
    setNumRequiredLocations,
    place,
    setPlace,
    gridVisible,
    setGridVisible,
    instruction,
    setInstruction,
    drawerOpened,
    toggleDrawer,
    closeDrawer,
    cookieModalOpened,
    openCookieModal,
    closeCookieModal,
    welcomeModalOpened,
    openWelcomeModal,
    closeWelcomeModal,
    tutorialOpened,
    openTutorial,
    closeTutorial,
    successModalOpened,
    openSuccessModal,
    closeSuccessModal,
    nextLocationModalOpened,
    openNextLocationModal,
    closeNextLocationModal,
    confirmModalOpened,
    openConfirmModal,
    closeConfirmModal,
    confirmLoginModeModalOpened,
    openConfirmLoginModeModal,
    closeConfirmLoginModeModal,
    hintModalOpened,
    openHintModal,
    closeHintModal,
    hintErrorModalOpened,
    openHintErrorModal,
    closeHintErrorModal,
    loginModeSwitchModalOpened,
    openLoginModeSwitchModal,
    closeLoginModeSwitchModal,
    loginModalOpened,
    openLoginModal,
    closeLoginModal,
    registerModalOpened,
    openRegisterModal,
    closeRegisterModal,
    incorrectModalOpened,
    openIncorrectModal,
    closeIncorrectModal,
    sdkModalOpened,
    openSdkModal,
    closeSdkModal,
    apiModalOpened,
    openApiModal,
    closeApiModal,
    useLocationModalOpened,
    openUseLocationModal,
    closeUseLocationModal,
    loginRegisterChooserModalOpened,
    openLoginRegisterChooserModal,
    closeLoginRegisterChooserModal,
    setSelectedLocation,
    closeAllModals,
    placesInputRef,
    modalOpened,
    businessSectorChooserModalOpened,
    openBusinessSectorChooserModal,
    closeBusinessSectorChooserModal,
    businessLinksOpened,
    toggleBusinessLinks,
    developerLinksOpened,
    toggleDeveloperLinks,
    individualLinksOpened,
    toggleIndividualLinks,
  };
  return (
    <AppContext.Provider value={sharedState}>
      <MantineProvider defaultColorScheme="auto">
        <GlobalKeyHandler />
        <Notifications position="bottom-center" />
        <CookiesModal />
        <WelcomeTutorial />
        <SelectLocationTutorial />
        <ConfirmModal />
        <ConfirmLoginModeModal />
        <NextLocationModal />
        <SuccessModal />
        <HintModal />
        <HintErrorModal />
        <LoginModeSwitchModal />
        <RegisterModal />
        <LoginModal />
        <IncorrectModal />
        <SDKModal />
        <APIModal />
        <UseLocationModal />
        <LoginRegisterChooserModal />
        <BusinessSectorChooser />
        <div className="container">
          <div className="header">
            <HeaderMegaMenu />
          </div>
          <div className="stepper-container">
            <Stepper active={active} onStepClick={setActive}>
              {steps}
              <Stepper.Completed>
                <Center>
                  Completed: Choose how you would like to save your locations.
                </Center>
              </Stepper.Completed>
            </Stepper>
          </div>
          <div className="map-container">
            <Map
              selectedSquareId={selectedSquareId}
              selectedLocation={selectedLocations[selectedLocationIndex]}
              setSelectedLocation={setSelectedLocation}
              onZoomWithoutGrid={(zoomLevel) => {
                if (zoomLevel === 3) return;
                setGridVisible(false);
                hideAllNotifications();
                setTimeout(() => {
                  showNotification({
                    title: "Continue zooming in",
                    message:
                      "Thinking of your deeply personal memory, continue zooming into that location.",
                    color: "lightblue",
                  });
                }, 750);
              }}
              onZoomWithGrid={(zoomLevel) => {
                if (
                  selectedLocations[selectedLocationIndex].lat !== 0 &&
                  selectedLocations[selectedLocationIndex].lng !== 0
                )
                  return;
                setGridVisible(true);
                // setTimeout(() => {
                //   showNotification({
                //     title: "Grid is now visible",
                //     message:
                //       "Click a grid square that covers the place of a deeply personal memory.",
                //     color: "darkblue",
                //   });
                // }, 750);
              }}
              onGridSquareClicked={(squareId) => {
                setSelectedSquareId(squareId);
                if (loginMode) {
                  if (numRequiredLocations === selectedLocations.length) {
                    setSelectedLocations([...selectedLocations]);
                    openLoginModal();
                  } else {
                    setActive(selectedLocations.length);
                    setSelectedLocationIndex(selectedLocations.length);
                    selectedLocations[selectedLocations.length] = {
                      lat: 0,
                      lng: 0,
                      confirmed: false,
                      hint: "",
                    };
                    setSelectedLocations([...selectedLocations]);
                    openWelcomeModal();
                  }
                } else {
                  setSelectedLocations([...selectedLocations]);
                  openHintModal();
                }
                console.log(selectedSquareId);
              }}
              center={center}
              zoom={zoom}
              getMap={getMap}
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
            />
            <Paper className="search-form">
              <SearchPlace />
            </Paper>
          </div>
          <div className="footer">
            <FooterCentered />
          </div>
        </div>
      </MantineProvider>
    </AppContext.Provider>
  );
}

export default App;
