import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  Title,
  List,
  ThemeIcon,
  rem,
  TextInput,
  PasswordInput,
  Flex,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { useAppContext } from "../AppContext";

export default function SuccessModal() {
  const {
    closeSuccessModal,
    openSuccessModal,
    successModalOpened,
    closeHintModal,
    openHintModal,
    hintModalOpened,
    selectedLocations,
    map,
    setConfirmMode,
  }: any = useAppContext;

  return (
    <>
      <Modal
        opened={successModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Success modal was closed by onBlur or other",
          });
          closeSuccessModal();
        }}
        title="Select one more location"
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <Text>
          You need a minimum of two secret locations to continue. Please select
          another secret location.
        </Text>
        <Flex direction="row" justify="right">
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Continue button in success modal",
              });
              closeSuccessModal();
              map.setZoom(3);
              setConfirmMode(false);
            }}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
