import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  Title,
  List,
  ThemeIcon,
  rem,
  TextInput,
  PasswordInput,
  Flex,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { useAppContext } from "../AppContext";

export default function NextLocationModal() {
  const {
    closeNextLocationModal,
    openNextLocationModal,
    nextLocationModalOpened,
    closeHintModal,
    openHintModal,
    hintModalOpened,
    selectedLocations,
    map,
    setConfirmMode,
    setSelectedLocations,
    setSelectedLocationIndex,
  }: any = useAppContext();

  return (
    <>
      <Modal
        opened={nextLocationModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Next location modal was closed by onBlur or other",
          });
          closeNextLocationModal();
        }}
        title="Select your next location"
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <Text>Click continue to select your next location.</Text>
        <Flex direction="row" justify="right">
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Continue button in next location modal",
              });
              closeNextLocationModal();
              map.setZoom(3);
              setConfirmMode(false);
              setSelectedLocationIndex(selectedLocations.length);
              selectedLocations.push({
                lat: 0,
                lng: 0,
                confirmed: false,
                hint: "",
              });
              setSelectedLocations([...selectedLocations]);
            }}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
