import CenterIdentity from "centeridentity";

import { API_KEY, API_SERVER } from "./config";
import { makeApiUrl, makeUrl } from "./makeUrl";
import axios from "axios";

export async function auth(
  selectedLocations: { lat: number; lng: number }[],
  email: string
) {
  const ci = new CenterIdentity({
    strength: "low",
    api_key: API_KEY,
    precision: 4,
    url_prefix: API_SERVER,
  });
  let privateIdentity;

  JSON.parse(JSON.stringify(selectedLocations)).sort((a: any, b: any) => {
    if (a.lat > b.lat) {
      return 1;
    }
    if (a.lat < b.lat) {
      return -1;
    }
    if (a.lat === b.lat) {
      if (a.lng > b.lng) {
        return 1;
      }
      if (a.lng < b.lng) {
        return -1;
      }
      return 0;
    }
  });
  let entropy: string = "";
  selectedLocations.forEach((selectedLocation) => {
    entropy +=
      selectedLocation.lat.toFixed(4) + selectedLocation.lng.toFixed(4);
  });
  try {
    privateIdentity = await ci.getV2(email, entropy);
  } catch (err) {
    privateIdentity = await ci.setFromNewV2(email, entropy);
  }
  try {
    const result = await axios.post(
      makeApiUrl("/challenge"),
      {
        api_key: ci.api_key,
        identity: ci.toObject(privateIdentity),
      },
      { withCredentials: true }
    );
    const signature = await ci.sign(
      result.data.challenge.challenge.message,
      privateIdentity
    );
    result.data.challenge.challenge.signature = signature;

    const res: any = await axios.post(
      makeApiUrl("/authenticate"),
      {
        hints: {
          private_username_hint: "",
          selected_location_hint: selectedLocations.map((item: any) => {
            return item.hint;
          }),
        },
        challenge_context: {
          api_key: ci.api_key,
          challenge: result.data.challenge.challenge,
          identity: ci.toObject(privateIdentity),
        },
      },
      { withCredentials: true }
    );

    return res.data.status;
  } catch (err) {
  } finally {
  }
}
