import { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  rem,
  TextInput,
  PasswordInput,
  Flex,
  LoadingOverlay,
  Loader,
  Title,
} from "@mantine/core";
import { makeUrl } from "../makeUrl";
import { useForm } from "@mantine/form";
import { IconAt, IconLock } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { auth } from "../auth";
import { useAppContext } from "../AppContext";

export default function LoginRegisterModal() {
  const {
    closeRegisterModal,
    registerModalOpened,
    hint,
    selectedLocations,
    setLeavingToDash,
  }: any = useAppContext();
  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmaiton, setPasswordConfirmation] = useState<string>("");
  const [visible, { open, close }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const handleChange = (event: any) => {
    form.setFieldValue("email", event.target.value.toLowerCase());
  };
  const go = async () => {
    logEvent("click", {
      target: "Continue button in login/register modal",
    });
    if (
      !form.values.email ||
      !confirmEmail ||
      form.values.email !== confirmEmail
    )
      return false;
    open();
    const authRes = await auth(selectedLocations, form.values.email);
    if (authRes === true) {
      setLeavingToDash(true);
      setTimeout(() => {
        window.location.href = makeUrl("/dashboard");
      }, 100);
    }
    close();
  };

  const inputRef: any = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [registerModalOpened]);
  return (
    <>
      <Modal
        opened={registerModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Login/register modal was closed by onBlur or other",
          });
          closeRegisterModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{ children: <Loader color="blue" /> }}
        />
        <Title>Save your location hints</Title>
        <Text>
          Enter your email so we can send your location hints to you if you
          forget your locations.
        </Text>
        <form onSubmit={form.onSubmit((values) => console.log(values))}>
          <TextInput
            ref={inputRef}
            tabIndex={1}
            key="email"
            name="email"
            type="email"
            label="Email"
            mt="md"
            value={form.values.email}
            error={form.errors.email}
            onChange={handleChange}
            leftSection={<IconAt style={{ width: rem(16), height: rem(16) }} />}
          />
          <TextInput
            tabIndex={2}
            key="email_confirm"
            name="email_confirm"
            type="email"
            label="Confirm email"
            mt="md"
            value={confirmEmail}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                go();
              }
            }}
            onChange={(e) =>
              setConfirmEmail(e.currentTarget.value.toLowerCase())
            }
            leftSection={<IconAt style={{ width: rem(16), height: rem(16) }} />}
          />
        </form>
        <Flex direction="row" justify="right">
          <Button
            disabled={
              !form.values.email ||
              !confirmEmail ||
              form.values.email !== confirmEmail
            }
            mt="md"
            onClick={go}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
