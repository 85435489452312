import React, { useCallback, useState } from "react";
import {
  TextInput,
  Button,
  Box,
  useMantineTheme,
  Paper,
  Flex,
  Autocomplete,
} from "@mantine/core";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { notifications } from "@mantine/notifications";
import { logEvent } from "../logEvent";
import { useAppContext } from "../AppContext";

export default function SearchPlace() {
  const {
    selectedPlace,
    setSelectedPlace,
    map,
    setZoom,
    closeWelcomeModal,
    place,
    setPlace,
    setSelectedSquareId,
    openUseLocationModal,
    selectedLocations,
    setSelectedLocations,
    selectedLocationIndex,
    closeAllModals,
    placesInputRef,
  }: any = useAppContext();
  const handlePlacesInput = useCallback(async () => {
    logEvent("event", {
      target: "Search place form",
    });
    setSelectedPlace({ label: place });
    let geoCode;
    try {
      geoCode = await geocodeByAddress(place);
    } catch (err) {
      return notifications.show({
        message: "Can't find that location, check your input and try again.",
        color: "red",
      });
    }
    const latLng = await getLatLng(geoCode[0]);
    let newZoom = 20;
    if (geoCode[0].geometry.bounds) {
      map.fitBounds(geoCode[0].geometry.bounds);
    } else if (geoCode[0].types.includes("country")) {
      newZoom = 5;
    } else if (geoCode[0].types.includes("administrative_area_level_1")) {
      newZoom = 7;
    } else if (
      geoCode[0].types.includes("locality") ||
      geoCode[0].types.includes("sublocality")
    ) {
      newZoom = 13;
    } else if (geoCode[0].types.includes("neighborhood")) {
      newZoom = 15;
    } else if (
      geoCode[0].types.includes("premise") ||
      geoCode[0].types.includes("subpremise") ||
      geoCode[0].types.includes("street_address")
    ) {
      newZoom = 20;
    } else if (
      geoCode[0].types.includes("point_of_interest") ||
      geoCode[0].types.includes("tourist_attraction")
    ) {
      return notifications.show({
        message:
          "Popular points of interest are strongly discouraged. Please try a different location.",
        color: "orange",
      });
    }
    if (!geoCode[0].geometry.bounds) {
      map && map.panTo({ ...latLng });
      map && map.setZoom(newZoom);
      setZoom(newZoom);
    }
    closeAllModals();
    if (map.getZoom() > 17) {
      selectedLocations[selectedLocationIndex] = {
        lat: latLng.lat + 0.0001,
        lng: latLng.lng + 0.0001,
        confirmed: false,
        hint: "",
      };
      setSelectedSquareId(
        `lat${selectedLocations[selectedLocationIndex].lat.toFixed(
          4
        )}lng${selectedLocations[selectedLocationIndex].lng.toFixed(4)}`
      );
      setSelectedLocations([...selectedLocations]);

      openUseLocationModal();
    }
  }, [closeWelcomeModal, place, selectedLocations, map]);
  return (
    <Flex direction="row">
      <TextInput
        ref={placesInputRef}
        value={place}
        onChange={(e) => {
          setPlace(e.currentTarget.value);
        }}
        onKeyDown={async (event) => {
          if (event.key === "Enter") {
            handlePlacesInput();
          }
        }}
        placeholder="555 N Happy Ln, Denver"
        mr="md"
        flex={1}
      />
      <Button
        onClick={() => {
          handlePlacesInput();
        }}
      >
        Search Location
      </Button>
    </Flex>
  );
}
