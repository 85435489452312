import { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  List,
  ThemeIcon,
  rem,
  TextInput,
  Flex,
  LoadingOverlay,
  Loader,
  Title,
  Checkbox,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import axios from "axios";
import { logEvent } from "../logEvent";
import { makeApiUrl } from "../makeUrl";
import { useAppContext } from "../AppContext";

export default function HintModal() {
  const {
    closeHintModal,
    hintModalOpened,
    openLoginModal,
    openRegisterModal,
    hint,
    setHint,
    openSuccessModal,
    selectedLocations,
    selectedLocationIndex,
    openNextLocationModal,
    setActive,
    setPlace,
    loginMode,
    numRequiredLocations,
    closeAllModals,
    openWelcomeModal,
    setSelectedLocationIndex,
    setSelectedLocations,
    openHintErrorModal,
    hintError,
    setHintError,
    setSuggestion,
  }: any = useAppContext();
  const [hintAssessment, setHintAssessment] = useState(false);
  const [visible, { open, close }] = useDisclosure(false);
  const [bypass, setBypass] = useState(false);
  const handleCheckHint = async () => {
    logEvent("click", { target: "Check hint button in hint modal" });
    if (bypass) {
      return go();
    }
    open();
    try {
      const result = await axios.post(makeApiUrl("/assess-hint"), {
        hint: hint,
      });
      if (result.data.error) {
        return setHintError(result.data.error);
      }
      if (result.data.assessment.grade === true) {
        go();
      } else {
        openHintErrorModal();
        setHintError(`${result.data.assessment.explanation}`);
        setSuggestion(`${result.data.assessment.suggestion}`);
      }
    } catch (err: any) {
      setHintError(err.message);
    }
    close();
  };
  const go = () => {
    setHintError("");

    closeAllModals();
    setActive(selectedLocations.length);
    selectedLocations[selectedLocationIndex].hint = hint;
    setHint("");
    setPlace("");
    if (selectedLocations.length === numRequiredLocations) {
      if (loginMode) {
        openLoginModal();
      } else {
        openRegisterModal();
      }
    } else {
      setSelectedLocationIndex(selectedLocations.length);
      selectedLocations[selectedLocations.length] = {
        lat: 0,
        lng: 0,
        confirmed: false,
        hint: "",
      };
      setSelectedLocations([...selectedLocations]);
      openWelcomeModal();
    }
  };

  const inputRef: any = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [hintModalOpened]);
  return (
    <>
      <Modal
        xOffset="1vh"
        opened={hintModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Hint modal was closed by onBlur or other",
          });
          closeHintModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{ children: <Loader color="blue" /> }}
        />
        {/* <Text mb="xl">The hint must:</Text>
        <List
          mb="xl"
          spacing="xs"
          size="sm"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
        >
          <List.Item>
            Provide enough information for you to remember it later
          </List.Item>
          <List.Item>Not reveal the location</List.Item>
        </List> */}
        <Title mb="xs">Describe this location</Title>
        <Text mb="xs">
          Enter a hint that does not reveal the location but provides enough
          information to recall it.
        </Text>
        <TextInput
          ref={inputRef}
          placeholder="The place I would play as a child"
          value={hint}
          onChange={(e) => {
            setHint(e.currentTarget.value);
            setHintAssessment(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !visible && hint && !hintAssessment) {
              handleCheckHint();
            }
          }}
          disabled={visible || hintAssessment}
          error={hintError}
        />
        <Flex direction="row" justify="right">
          <Button
            mt="md"
            onClick={handleCheckHint}
            disabled={visible || !hint || hintAssessment}
          >
            Check hint
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
