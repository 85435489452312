import {
  Modal,
  Text,
  Title,
  Checkbox,
  Flex,
  Button,
  Select,
  NumberInput,
} from "@mantine/core";
import { useState } from "react";
import { logEvent } from "../logEvent";
import SearchPlace from "./SearchPlace";
import { useAppContext } from "../AppContext";

export default function WelcomeTutorial() {
  const {
    closeWelcomeModal,
    welcomeModalOpened,
    setSelectedPlace,
    setZoom,
    map,
    place,
    setPlace,
    setNumRequiredLocations,
    numRequiredLocations,
    setSelectedSquareId,
    openUseLocationModal,
    selectedLocations,
    setSelectedLocations,
    selectedLocationIndex,
    loginMode,
  }: any = useAppContext();
  return (
    <>
      <Modal
        opened={welcomeModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Welcome modal was closed by onBlur or other",
          });
          closeWelcomeModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <Title order={3} mb="xs">
          {selectedLocationIndex === 0
            ? loginMode
              ? "Welcome back"
              : "Welcome"
            : "Select next location"}
        </Title>
        <Text mb="md">
          {`${numRequiredLocations} secret location${
            numRequiredLocations > 1 ? "s" : ""
          } ${numRequiredLocations > 1 ? "are" : "is"} required.`}
        </Text>
        <SearchPlace />
      </Modal>
    </>
  );
}
