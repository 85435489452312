import { notifications } from "@mantine/notifications";

const displayedNotifications = new Set();

export const showNotification = (notificationOptions: any) => {
  const { title } = notificationOptions;
  if (
    title === "Grid is now visible" &&
    displayedNotifications.has("Location is too close")
  )
    return;
  if (
    title === "Grid is now visible" &&
    displayedNotifications.has("Continue zooming in")
  ) {
    hideAllNotifications();
  }

  if (!displayedNotifications.has(title)) {
    displayedNotifications.add(title);

    notifications.show({
      autoClose: 3000,
      ...notificationOptions,
      onClose: () => {
        displayedNotifications.delete(title);
      },
    });
  }
};

export const hideAllNotifications = () => {
  notifications.clean();
  displayedNotifications.clear();
};
