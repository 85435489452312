import { Button, Paper, Text, Group, CloseButton, Modal } from "@mantine/core";
import axios from "axios";
import { useState } from "react";
import { logEvent } from "../logEvent";
import { useAppContext } from "../AppContext";

export function CookiesModal() {
  const {
    cookieModalOpened,
    closeCookieModal,
    openWelcomeModal,
    closeAllModals,
  }: any = useAppContext();
  const [cookieRes, setCookieRes] = useState<null | string>(null);
  const cookies = window.localStorage.getItem("cookies");
  if (cookies) {
    return <></>;
  }

  return (
    <Modal
      opened={cookieModalOpened}
      onClose={() => {
        logEvent("event", {
          target: "Cookie modal was closed by onBlur or other",
        });
        closeAllModals();
        const tutorial = localStorage.getItem("tutorial");
        openWelcomeModal();
      }}
      title="Cookie disclosure"
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      yOffset={60}
      overlayProps={{
        fixed: true,
        style: { top: 60 },
      }}
    >
      <Paper withBorder p="lg" radius="md" shadow="md">
        <Group justify="space-between" mb="xs">
          <Text fz="md" fw={500}>
            Allow cookies
          </Text>
        </Group>
        <Text c="dimmed" fz="xs">
          We use cookies to maintain an authenticated user session while logged
          in. Cookies on this site are not used for any other purpose. Please
          accept all cookies to continue.
        </Text>
        <Group justify="flex-end" mt="md">
          {/* <Button variant="default" size="xs">
          Cookies preferences
        </Button> */}
          <Button
            variant="outline"
            size="xs"
            onClick={async () => {
              logEvent("click", { target: "Accept all cookies button" });
              window.localStorage.setItem("cookies", "1");
              setCookieRes("1");
              const tutorial = localStorage.getItem("tutorial");
              closeAllModals();
              openWelcomeModal();
            }}
          >
            Accept all
          </Button>
        </Group>
      </Paper>
    </Modal>
  );
}
