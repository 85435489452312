import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  Title,
  List,
  ThemeIcon,
  rem,
  TextInput,
  PasswordInput,
  Flex,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { hideAllNotifications, showNotification } from "../showNotification";
import WelcomeTutorial from "./WelcomeModal";
import { useAppContext } from "../AppContext";
import { useEffect } from "react";
import StaticMap from "./StaticMap";

export default function UseLocationModal() {
  const {
    closeUseLocationModal,
    openUseLocationModal,
    useLocationModalOpened,
    closeHintModal,
    openHintModal,
    hintModalOpened,
    selectedLocations,
    map,
    setConfirmMode,
    setSelectedSquareId,
    selectedLocationIndex,
    setSelectedLocations,
    openConfirmModal,
    loginMode,
    openConfirmLoginModeModal,
    openWelcomeModal,
    closeAllModals,
    numRequiredLocations,
    openLoginModal,
    setSelectedLocationIndex,
    setActive,
    setInstruction,
    setCenter,
  }: any = useAppContext();

  useEffect(() => {
    setCenter({
      lat: selectedLocations[selectedLocationIndex].lat + 0.0003,
      lng: selectedLocations[selectedLocationIndex].lng + 0.0001,
    });
    if (map) map.setZoom(20);
  }, [selectedLocations, selectedLocationIndex, map]);

  return (
    <>
      <Modal
        opened={useLocationModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Confirm modal was closed by onBlur or other",
          });
          closeUseLocationModal();
          selectedLocations[selectedLocationIndex] = {
            lat: 0,
            lng: 0,
            confirmed: false,
            hint: "",
          };
          setSelectedLocations([...selectedLocations]);
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          display: "none",
        }}
      >
        <Title>Use this location?</Title>
        <Text>
          You can use the already selected green square or select a different
          square on your own.
        </Text>
        <Flex direction="column" justify="space-between">
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Confirm selection button in confirm modal",
              });

              closeAllModals();
              if (loginMode) {
                setActive(selectedLocations.length);
                setSelectedSquareId("-");
                closeAllModals();
                selectedLocations[selectedLocationIndex].confirmed = true;
                setSelectedLocations([...selectedLocations]);
                if (selectedLocations.length === numRequiredLocations)
                  return openLoginModal();
                setSelectedLocationIndex(selectedLocations.length);

                selectedLocations[selectedLocations.length] = {
                  lat: 0,
                  lng: 0,
                  confirmed: false,
                  hint: "",
                };
                setSelectedLocations([...selectedLocations]);
                map.setZoom(3);
                openWelcomeModal();
              } else {
                openHintModal();
              }
            }}
          >
            Yes, use this location
          </Button>
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Confirm selection button in confirm modal",
              });
              closeUseLocationModal();
              setInstruction(
                "Click the desired square or use the arrow keys then press Enter."
              );
            }}
          >
            Close, but let me select the exact location
          </Button>
          {!loginMode && (
            <Button
              mt="md"
              onClick={() => {
                logEvent("click", {
                  target: "Try again button in confirm modal",
                });

                closeAllModals();
                selectedLocations[selectedLocationIndex] = {
                  lat: 0,
                  lng: 0,
                  confirmed: false,
                  hint: "",
                };
                setSelectedLocations([...selectedLocations]);
                openWelcomeModal();
              }}
            >
              No, start over.
            </Button>
          )}
        </Flex>
        <StaticMap />
      </Modal>
    </>
  );
}
