import { AnyAaaaRecord } from "dns";
import { useEffect, useRef } from "react";

const useLeavePrompt = (message: string, leavingToDash: boolean) => {
  useEffect(() => {
    const handlePopState = (e: PopStateEvent) => {
      if (e.state && e.state.isBackNavigation) {
        if (leavingToDash) return;
        if (!window.confirm(message)) {
          window.history.pushState({ isBackNavigation: true }, "");
        }
      }
    };

    const pushInitialState = () => {
      window.history.pushState({ isBackNavigation: false }, "");
    };

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (leavingToDash) return;
      e.preventDefault();
      e.returnValue = ""; // Required for some browsers to show the prompt
    };

    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    pushInitialState();

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [message, leavingToDash]);
};

export default useLeavePrompt;
