import React from "react";
import { useAppContext } from "../AppContext";
import { Center } from "@mantine/core";

export default function StaticMap({ lat, lng, zoom }: any) {
  const { selectedLocations, selectedLocationIndex }: any = useAppContext();
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
    selectedLocations[selectedLocationIndex].lat + 0.0001
  },${
    selectedLocations[selectedLocationIndex].lng + 0.0001
  }&zoom=22&size=600x300&maptype=hybrid&key=AIzaSyDEbmqlzlkU3mErAG-PPdPEbTrv6opHmag`;

  return (
    <Center mt="md">
      <img src={mapUrl} alt="Map" style={{ width: 400 }} />
    </Center>
  );
}
