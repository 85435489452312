import { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  rem,
  TextInput,
  PasswordInput,
  Flex,
  LoadingOverlay,
  Loader,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAt, IconLock } from "@tabler/icons-react";
import CenterIdentity from "centeridentity";
import axios from "axios";
import { API_KEY, API_SERVER } from "../config";
import { logEvent } from "../logEvent";
import { makeApiUrl, makeUrl } from "../makeUrl";
import { auth } from "../auth";
import { useAppContext } from "../AppContext";
export default function LoginRegisterModal() {
  const {
    closeLoginModal,
    loginModalOpened,
    hint,
    selectedLocations,
    setLeavingToDash,
  }: any = useAppContext();
  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmaiton, setPasswordConfirmation] = useState<string>("");
  const [visible, { open, close }] = useDisclosure(false);
  const ci = new CenterIdentity({
    strength: "low",
    api_key: API_KEY,
    precision: 4,
    url_prefix: API_SERVER,
  });
  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });
  const handleChange = (event: any) => {
    form.setFieldValue("email", event.target.value.toLowerCase());
  };
  const go = async () => {
    logEvent("click", {
      target: "Continue button in login/register modal",
    });
    open();
    const authRes = await auth(selectedLocations, form.values.email);
    if (authRes === true) {
      setLeavingToDash(true);
      setTimeout(() => {
        window.location.href = makeUrl("/dashboard");
      }, 100);
    }
    close();
  };
  const inputRef: any = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [loginModalOpened]);
  return (
    <>
      <Modal
        opened={loginModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Login/register modal was closed by onBlur or other",
          });
          closeLoginModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <LoadingOverlay
          visible={visible}
          loaderProps={{ children: <Loader color="blue" /> }}
        />
        <Title>Login</Title>
        <Text>Enter your email and click the continue button to login.</Text>
        <form onSubmit={form.onSubmit((values) => console.log(values))}>
          <TextInput
            ref={inputRef}
            type="email"
            label="Email"
            mt="md"
            value={form.values.email}
            error={form.errors.email}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                go();
              }
            }}
            onBlur={() => {
              if (form.values.email !== "") {
                form.validateField("email");
              }
            }}
            leftSection={<IconAt style={{ width: rem(16), height: rem(16) }} />}
          />
        </form>
        <Flex direction="row" justify="right">
          <Button disabled={!form.values.email} mt="md" onClick={go}>
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
