import {
  Anchor,
  Button,
  Checkbox,
  Flex,
  Modal,
  NumberInput,
  Text,
  Title,
} from "@mantine/core";
import { useAppContext } from "../AppContext";
import { logEvent } from "../logEvent";
import { makeApiUrl } from "../makeUrl";
import { useState } from "react";

export default function LoginRegisterChooserModal() {
  const {
    closeLoginRegisterChooserModal,
    loginRegisterChooserModalOpened,
    openWelcomeModal,
    closeAllModals,
    setLoginMode,
    numRequiredLocations,
    setNumRequiredLocations,
  }: any = useAppContext();
  const [customize, setCustomize] = useState(false);
  return (
    <>
      <Modal
        opened={loginRegisterChooserModalOpened}
        onClose={() => {
          logEvent("event", {
            target:
              "Login register chooser modal was closed by onBlur or other",
          });
          closeLoginRegisterChooserModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60, bottom: 68 },
        }}
      >
        <Title>My Identity</Title>
        <Flex direction="column">
          <Text mt="xs">Don't have an identity yet?</Text>
          <Button
            onClick={() => {
              closeAllModals();
              openWelcomeModal();
            }}
          >
            Create a new identity
          </Button>
          <Text mt="xs">Already have an identity?</Text>
          <Button
            onClick={() => {
              closeAllModals();
              setLoginMode(true);
              openWelcomeModal();
            }}
          >
            Access an existing identity
          </Button>

          <Anchor
            mt="xs"
            href={makeApiUrl(
              "/identity",
              "next=/authenticate&mode=auth&stage=signin"
            )}
          >
            Legacy log-in
          </Anchor>
        </Flex>
        <Checkbox
          mt="md"
          label="Customize requirements"
          checked={customize}
          onChange={(e) => {
            setCustomize(e.currentTarget.checked);
          }}
        />
        {customize && (
          <>
            <NumberInput
              label="Required locations"
              description="Number of required locations. 3 locations = 8 character password. 14 locations = 24 word mnemonic seed phrase"
              mt="xs"
              value={numRequiredLocations}
              onChange={(value) => {
                setNumRequiredLocations(value);
              }}
              min={1}
              allowNegative={false}
            />
            {numRequiredLocations < 3 && (
              <Text c="grey" size="xs" mt="xs">
                Less than 3 locations can be used if combined with a username
                that is not public and numer of authentication attempts is
                limited.
              </Text>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
