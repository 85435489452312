import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Button,
  Text,
  Title,
  List,
  ThemeIcon,
  rem,
  TextInput,
  PasswordInput,
  Flex,
  Anchor,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import { logEvent } from "../logEvent";
import { notifications } from "@mantine/notifications";
import { hideAllNotifications, showNotification } from "../showNotification";
import { makeApiUrl } from "../makeUrl";
import { useAppContext } from "../AppContext";

export default function LoginModeSwitchModal() {
  const {
    setLoginMode,
    loginMode,
    loginModeSwitchModalOpened,
    closeLoginModeSwitchModal,
  }: any = useAppContext();

  return (
    <>
      <Modal
        opened={loginModeSwitchModalOpened}
        onClose={() => {
          logEvent("event", {
            target: "Incorrect selection modal was closed by onBlur or other",
          });
          closeLoginModeSwitchModal();
        }}
        title="Log-in mode activated"
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60 },
        }}
      >
        <Text mb="md">You may now use the map to log-in.</Text>
        <Text mb="md">Select your three locations.</Text>
        <Flex direction="row" justify="space-between" align="center">
          <Anchor
            href={makeApiUrl(
              "/identity",
              "next=/authenticate&mode=auth&stage=signin"
            )}
          >
            Legacy log-in
          </Anchor>
          <Button
            mt="md"
            onClick={() => {
              logEvent("click", {
                target: "Switch to login mode in login modal",
              });
              closeLoginModeSwitchModal();
              setLoginMode(true);
              showNotification({
                title: "Log in mode activated",
                message: "You may now use the map to log in.",
              });
            }}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
