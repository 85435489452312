import axios from "axios";
import { makeUrl } from "./makeUrl";

export async function logEvent(name: string, props: any) {
  try {
    props["source"] = "home page app";
    if (window.location.hostname === "localhost") return;
    await axios.post(makeUrl("/event"), {
      name: name,
      props: props,
    });
  } catch (err) {}
}
