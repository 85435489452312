export let API_KEY: string;
export let API_SERVER: string;

if (window.location.hostname === "localhost") {
  API_KEY =
    "MEQCICGLNNdXjr0/gYeWF39uvh0ZCyxw59Scqs4ggk7blnDXAiA6qiJmjl+3CyXE7AOlBkozDr/PV/996C+1Wpb6+Ljg3g==";
  API_SERVER = "http://localhost:8005";
} else {
  API_KEY =
    "MEQCIC7ADPLI3VPDNpQPaXAeB8gUk2LrvZDJIdEg9C12dj5PAiB61Te/sen1D++EJAcgnGLH4iq7HTZHv/FNByuvu4PrrA==";
  API_SERVER = "https://centeridentity.com";
}
