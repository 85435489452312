import { CodeHighlight, CodeHighlightTabs } from "@mantine/code-highlight";
import {
  Button,
  Loader,
  Modal,
  Paper,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { Map } from "centeridentityreact";
import { useAppContext } from "../AppContext";
import axios from "axios";
import { makeApiUrl } from "../makeUrl";
import { useState } from "react";

export default function APIModal() {
  const { apiModalOpened, closeApiModal }: any = useAppContext();
  const [apiResponseHint, setApiResponseHint] = useState("");
  const [apiResponseSecurityQuestion, setApiResponseSecurityQuestion] =
    useState("");
  const [
    apiResponseSecurityQuestionAnswer,
    setApiResponseSecurityQuestionAnswer,
  ] = useState("");
  const [locationHint, setLocationHint] = useState("");
  const [securityQuestion, setSecurityQuestion] = useState("");
  const [securityQuestionAnswer, setSecurityQuestionAnswer] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const code = `
const [selectedLocations, setSelectedLocations] = useState<
  Array<{ lat: number; lng: number; confirmed: boolean; hint?: string }>
>([
  {
    lat: 0,
    lng: 0,
    confirmed: false,
    hint: "",
  },
]);
const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>(0);

const setSelectedLocationHandler = (location: { lat: number; lng: number; confirmed: boolean; }) => {
  // Prompt user for hint.
  location.hint = "some hint from user prompt";
  selectedLocations[selectedLocationIndex] = location;
  setSelectedLocations([...selectedLocations]);
  setSelectedLocationIndex(selectedLocations.length - 1)
}

<Map
  setSelectedLocation={setSelectedLocationHandler}
  mapContainerStyle={{
    width: "100%",
    height: "100%",
  }}
/>
  `;
  const data = [
    {
      name: "selectedLocation",
      type: `
{
  lat: number;
  lng: number;
}`,
      description:
        "This is the currently selected geocoordinate rounded to the precision prop.",
    },
    {
      name: "setSelectedLocation",
      type: `
(location: {
  lat: number;
  lng: number;
  confirmed: boolean;
  hint: string;
}) => void;`,
      description: "Event hook for when a user selects a location.",
    },
    {
      name: "onZoomWithGrid",
      type: `(zoomLevel: number) => void;`,
      description:
        "Event hook for when a user changes the zoom of the map when selection grid is not visible.",
    },
    {
      name: "onZoomWithoutGrid",
      type: `(zoomLevel: number) => void;`,
      description:
        "Event hook for when a user changes the zoom of the map when selection grid is visible.",
    },
    {
      name: "onGridSquareClicked",
      type: `(squareId: string) => void;`,
      description:
        "Event hook to set the grid square ID when the user selects a grid square.",
    },
    {
      name: "selectedSquareId",
      type: `string;`,
      description: "The concatenated coordinates for the selected square.",
    },
    {
      name: "center",
      type: `
{
  lat: number;
  lng: number;
}`,
      description: "Sets the coordinates of where the map view is centered.",
    },
    {
      name: "zoom",
      type: `number;`,
      description: "Sets the zoom level of the map view.",
    },
    {
      name: "getMap",
      type: `(map: google.maps.Map) => void;`,
      description: "Hook to get the map object for further customization.",
    },
    {
      name: "mapContainerStyle",
      type: `
{
  width: string | number;
  height: string | number;
};`,
      description: "Hook to get the map object for further customization.",
    },
  ];
  const rows = data.map((item) => {
    return (
      <Table.Tr key={item.name}>
        <Table.Td>{item.name}</Table.Td>
        <Table.Td>
          <CodeHighlight code={item.type} language="tsx" />
        </Table.Td>
        <Table.Td style={{ minWidth: 400 }}>{item.description}</Table.Td>
      </Table.Tr>
    );
  });

  const keyPairCode = `
JSON.parse(JSON.stringify(selectedLocations)).sort(
  (a: any, b: any) => {
    if (a.lat > b.lat) {
      return 1;
    }
    if (a.lat < b.lat) {
      return -1;
    }
    if (a.lat === b.lat) {
      if (a.lng > b.lng) {
        return 1;
      }
      if (a.lng < b.lng) {
        return -1;
      }
      return 0;
    }
  }
);
const entropy =
  selectedLocations[0].lat.toFixed(4) +
  selectedLocations[0].lng.toFixed(4) +
  selectedLocations[1].lat.toFixed(4) +
  selectedLocations[1].lng.toFixed(4) +
  selectedLocations[2].lat.toFixed(4) +
  selectedLocations[2].lng.toFixed(4);`;
  const locationHintApi = async () => {
    setApiLoading(true);
    const res = await axios.post(makeApiUrl("/assess-hint"), {
      hint: locationHint,
    });
    setApiLoading(false);
    setApiResponseHint(JSON.stringify(res.data, null, 4));
  };
  const securityQuestionApi = async () => {
    setApiLoading(true);
    const res = await axios.post(makeApiUrl("/assess-security-question"), {
      security_question: securityQuestion,
    });
    setApiLoading(false);
    setApiResponseSecurityQuestion(JSON.stringify(res.data, null, 4));
  };
  const securityQuestionAnswerApi = async () => {
    setApiLoading(true);
    const res = await axios.post(
      makeApiUrl("/assess-security-question-answer"),
      {
        security_question_id: "651c9e10337634fe01715648",
        answer: securityQuestionAnswer,
      }
    );
    setApiLoading(false);
    setApiResponseSecurityQuestionAnswer(JSON.stringify(res.data, null, 4));
  };
  return (
    <Modal opened={apiModalOpened} onClose={closeApiModal} size="100%">
      <Title mb="md">API Documentation</Title>
      <Tabs defaultValue="assess-hint">
        <Tabs.List mb="md">
          <Tabs.Tab value="assess-hint">/assess-hint</Tabs.Tab>
          <Tabs.Tab value="assess-security-question">
            /assess-security-question
          </Tabs.Tab>
          <Tabs.Tab value="assess-security-question-answer">
            /assess-security-question-answer
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="assess-hint">
          <Title order={2} mb="xs">
            Test
          </Title>
          <Paper withBorder p="xs" mb="xs">
            <Text>
              Enter a hint for a location. Imagine you just selected a location
              on the map, now you need to give a hint for that location.
            </Text>
            <TextInput
              mb="xs"
              value={locationHint}
              placeholder="My favorite fishing spot"
              onChange={(e) => {
                setLocationHint(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  locationHintApi();
                }
              }}
            />
            <Button mb="xs" onClick={locationHintApi} disabled={apiLoading}>
              Submit {apiLoading && <Loader ml="xs" size="xs" color="green" />}
            </Button>
          </Paper>
          {apiResponseHint.length > 0 && (
            <Paper>
              <CodeHighlight code={apiResponseHint} />
            </Paper>
          )}
          <Title order={2} mb="xs">
            Request
          </Title>
          <CodeHighlight
            code={`POST /assess-hint?api_key=YOUR_API_KEY`}
            language="tsx"
          />
          <Title order={2} mb="xs" mt="md">
            Payload
          </Title>
          <CodeHighlight code={`{"hint": "input hint"}`} language="json" />
          <Title order={2} mb="xs" mt="md">
            Example Response
          </Title>
          <CodeHighlight
            code={`
{
  "hint": "input hint",
  "assessment": {
    "grade": false,
    "explanation": "The hint is unintelligible input.",
    "suggestion": "Please provide a more descriptive hint."
  }
}`}
            language="tsx"
          />
        </Tabs.Panel>
        <Tabs.Panel value="assess-security-question">
          <Title order={2} mb="xs">
            Test
          </Title>
          <Paper withBorder p="xs" mb="xs">
            <Text>
              Enter a security question. This questions should ask the user to
              recall something personal about themselves.
            </Text>
            <TextInput
              mb="xs"
              value={securityQuestion}
              placeholder="Where is your favorite fishing spot?"
              onChange={(e) => {
                setSecurityQuestion(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  securityQuestionApi();
                }
              }}
            />
            <Button mb="xs" onClick={securityQuestionApi} disabled={apiLoading}>
              Submit {apiLoading && <Loader ml="xs" size="xs" color="green" />}
            </Button>
          </Paper>
          {apiResponseSecurityQuestion.length > 0 && (
            <Paper>
              <CodeHighlight code={apiResponseSecurityQuestion} />
            </Paper>
          )}
          <Title order={2} mb="xs">
            Request
          </Title>
          <CodeHighlight
            code={`POST /assess-security-question?api_key=YOUR_API_KEY`}
            language="tsx"
          />
          <Title order={2} mb="xs" mt="md">
            Payload
          </Title>
          <CodeHighlight
            code={`{"security_question": "input security question"}`}
            language="json"
          />
          <Title order={2} mb="xs" mt="md">
            Example Response
          </Title>
          <CodeHighlight
            code={`
{
  "security_question": "input security question",
  "assessment": {
    "grade": false,
    "explanation": "The security question is too vague.",
    "suggestion": "Please provide a more descriptive security question."
  }
}`}
            language="tsx"
          />
        </Tabs.Panel>
        <Tabs.Panel value="assess-security-question-answer">
          <Title order={2} mb="xs">
            Test
          </Title>
          <Paper withBorder p="xs" mb="xs">
            <Text>Where is your favorite fishing spot?</Text>
            <TextInput
              mb="xs"
              value={securityQuestionAnswer}
              placeholder="Lake placid"
              onChange={(e) => {
                setSecurityQuestionAnswer(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  securityQuestionAnswerApi();
                }
              }}
            />
            <Button
              mb="xs"
              onClick={securityQuestionAnswerApi}
              disabled={apiLoading}
            >
              Submit {apiLoading && <Loader ml="xs" size="xs" color="green" />}
            </Button>
          </Paper>
          {apiResponseSecurityQuestionAnswer.length > 0 && (
            <Paper>
              <CodeHighlight code={apiResponseSecurityQuestionAnswer} />
            </Paper>
          )}
          <Title order={2} mb="xs">
            Request
          </Title>
          <CodeHighlight
            code={`POST /assess-security-question-answer?api_key=YOUR_API_KEY`}
            language="tsx"
          />
          <Title order={2} mb="xs" mt="md">
            Payload
          </Title>
          <CodeHighlight
            code={`
{
  "security_question_id": "input security question", 
  "answer": "my answer to the security question."
}`}
            language="json"
          />
          <Title order={2} mb="xs" mt="md">
            Example Response
          </Title>
          <CodeHighlight
            code={`
{
  "answer": "input security question answer",
  "assessment": {
    "grade": false,
    "explanation": "The security question answer is too vague.",
    "suggestion": "Please provide a more descriptive security question answer."
  }
}`}
            language="tsx"
          />
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
}
