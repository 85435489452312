import {
  Anchor,
  Button,
  Checkbox,
  Flex,
  Modal,
  NumberInput,
  Text,
  Title,
} from "@mantine/core";
import { useAppContext } from "../AppContext";
import { logEvent } from "../logEvent";
import { makeApiUrl, makeUrl } from "../makeUrl";
import { useState } from "react";

export default function BusinessSectorChooser() {
  const {
    closeBusinessSectorChooserModal,
    businessSectorChooserModalOpened,
    openLoginRegisterChooserModal,
    openWelcomeModal,
    closeAllModals,
    setLoginMode,
    numRequiredLocations,
    setNumRequiredLocations,
    toggleDeveloperLinks,
    developerLinksOpened,
    openSdkModal,
    openApiModal,
    setLeavingToDash,
  }: any = useAppContext();
  const [customize, setCustomize] = useState(false);
  return (
    <>
      <Modal
        opened={businessSectorChooserModalOpened}
        onClose={() => {
          logEvent("event", {
            target:
              "Login register chooser modal was closed by onBlur or other",
          });
          closeBusinessSectorChooserModal();
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
        closeOnEscape={false}
        yOffset={60}
        overlayProps={{
          fixed: true,
          style: { top: 60, bottom: 68 },
        }}
      >
        <Title order={2}>Welcome to Center Identity.</Title>
        <Flex direction="column">
          <Title mt="xs" order={3} mb="xs">
            Are you a business, individual, or developer?
          </Title>
          <Button
            mb="md"
            onClick={() => {
              setLeavingToDash(true);
              setTimeout(() => {
                window.location.href = makeUrl("/business");
              }, 100);
            }}
          >
            Business
          </Button>
          <Button
            mb="md"
            onClick={() => {
              closeAllModals();
              openLoginRegisterChooserModal();
            }}
          >
            Individual
          </Button>
          <Text mb="xs">Developer:</Text>
          <Flex direction="row" justify="space-evenly">
            <Button
              fullWidth
              mb="md"
              mr="md"
              onClick={() => {
                closeAllModals();
                openSdkModal();
              }}
            >
              SDK
            </Button>
            <Button
              fullWidth
              mb="md"
              onClick={() => {
                closeAllModals();
                openApiModal();
              }}
            >
              API
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
