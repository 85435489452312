import React, {
  useEffect,
  KeyboardEvent,
  KeyboardEventHandler,
  useCallback,
} from "react";
import { useAppContext } from "../AppContext";
import ConfirmLoginModeModal from "./ConfirmLoginModeModal";

const GlobalKeyHandler = () => {
  const {
    loginMode,
    selectedLocations,
    selectedLocationIndex,
    setSelectedLocations,
    openRegisterModal,
    openLoginModal,
    setConfirmMode,
    closeAllModals,
    gridVisible,
    setSelectedSquareId,
    selectedSquareId,
    setSelectedLocation,
    openConfirmLoginModeModal,
    openHintModal,
    modalOpened,
    numRequiredLocations,
    openWelcomeModal,
    setActive,
    setSelectedLocationIndex,
  }: any = useAppContext();
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // Check if the pressed key is the one you want to handle

      if (event.key === "~") {
        if (window.location.href.indexOf("localhost") === -1) return;
        setConfirmMode(true);
        // Trigger your handler
        console.log("Specific key pressed!");
        // Call your specific handler function here
        setRegisterHandler();
      }
      if (event.key === "!") {
        if (window.location.href.indexOf("localhost") === -1) return;
        setConfirmMode(true);
        // Trigger your handler
        console.log("Specific key pressed!");
        // Call your specific handler function here
        setLoginHandler();
      }
      if (event.key === "ArrowLeft" && !modalOpened()) {
        event.preventDefault();
        selectedLocations[selectedLocationIndex].lng -= 0.0001;
        setSelectedLocations([...selectedLocations]);
        setSelectedSquareId(
          `lat${selectedLocations[selectedLocationIndex].lat.toFixed(
            4
          )}lng${selectedLocations[selectedLocationIndex].lng.toFixed(4)}`
        );
      }
      if (event.key === "ArrowRight" && !modalOpened()) {
        event.preventDefault();
        selectedLocations[selectedLocationIndex].lng += 0.0001;
        setSelectedLocations([...selectedLocations]);
        setSelectedSquareId(
          `lat${selectedLocations[selectedLocationIndex].lat.toFixed(
            4
          )}lng${selectedLocations[selectedLocationIndex].lng.toFixed(4)}`
        );
      }
      if (event.key === "ArrowUp" && !modalOpened()) {
        event.preventDefault();
        selectedLocations[selectedLocationIndex].lat += 0.0001;
        setSelectedLocations([...selectedLocations]);
        setSelectedSquareId(
          `lat${selectedLocations[selectedLocationIndex].lat.toFixed(
            4
          )}lng${selectedLocations[selectedLocationIndex].lng.toFixed(4)}`
        );
      }
      if (event.key === "ArrowDown" && !modalOpened()) {
        event.preventDefault();
        selectedLocations[selectedLocationIndex].lat -= 0.0001;
        setSelectedLocations([...selectedLocations]);
        setSelectedSquareId(
          `lat${selectedLocations[selectedLocationIndex].lat.toFixed(
            4
          )}lng${selectedLocations[selectedLocationIndex].lng.toFixed(4)}`
        );
      }
      if (event.key === "Enter" && !modalOpened()) {
        event.preventDefault();
        if (loginMode) {
          if (numRequiredLocations === selectedLocations.length) {
            setSelectedLocations([...selectedLocations]);
            openLoginModal();
          } else {
            setActive(selectedLocations.length);
            setSelectedLocationIndex(selectedLocations.length);
            selectedLocations[selectedLocations.length] = {
              lat: 0,
              lng: 0,
              confirmed: false,
              hint: "",
            };
            setSelectedLocations([...selectedLocations]);
            openWelcomeModal();
          }
        } else {
          setSelectedLocations([...selectedLocations]);
          openHintModal();
        }
      }
    },
    [
      selectedLocations,
      selectedLocationIndex,
      setSelectedLocations,
      setConfirmMode,
      setSelectedSquareId,
      openConfirmLoginModeModal,
      openHintModal,
      modalOpened,
      loginMode,
      numRequiredLocations,
      openWelcomeModal,
    ]
  );

  useEffect(() => {
    // Add event listener for keydown
    window.addEventListener("keydown", handleKeyPress as any);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress as any);
    };
  }, [handleKeyPress]);

  const setLoginHandler = () => {
    // Your specific handler code here
    console.log("Handler function executed");
    setSelectedLocations([
      {
        lat: 1.0,
        lng: 1.0,
        confirmed: true,
        hint: "hint 1",
      },
      {
        lat: 2.0,
        lng: 2.0,
        confirmed: true,
        hint: "hint 2",
      },
      {
        lat: 3.0,
        lng: 3.0,
        confirmed: true,
        hint: "hint 3",
      },
    ]);
    closeAllModals();
    openLoginModal();
  };
  const setRegisterHandler = () => {
    // Your specific handler code here
    console.log("Handler function executed");
    setSelectedLocations([
      {
        lat: 1.0,
        lng: 1.0,
        confirmed: true,
        hint: "hint 1",
      },
      {
        lat: 2.0,
        lng: 2.0,
        confirmed: true,
        hint: "hint 2",
      },
      {
        lat: 3.0,
        lng: 3.0,
        confirmed: true,
        hint: "hint 3",
      },
    ]);
    closeAllModals();
    openRegisterModal();
  };

  return null; // This component does not render anything
};

export default GlobalKeyHandler;
