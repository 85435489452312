import { Anchor, Group, ActionIcon, rem, Image } from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandLinkedin,
} from "@tabler/icons-react";
import classes from "./FooterCentered.module.scss";
import { makeUrl } from "../makeUrl";

const links = [
  { link: "/privacy-policy", label: "Privacy" },
  { link: "/terms-of-service", label: "Terms of Service" },
  {
    link: "/centeridentitystatic/investor-pitch-deck.pdf",
    label: "News & Events",
  },
  { link: "/identity-blog", label: "Blog" },
];

export function FooterCentered(props: any) {
  const {} = props;

  const items = links.map((link) => (
    <Anchor
      c="dimmed"
      key={link.label}
      href={makeUrl(link.link)}
      lh={1}
      size="sm"
      target="_blank"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <div className={classes.inner}>
        <Image
          src="https://centeridentity.com/centeridentitystatic/center-identity-logo-square.png"
          height={35}
        />

        <Group className={classes.links}>{items}</Group>

        <Group gap="xs" justify="flex-end" wrap="nowrap">
          <Anchor href="https://twitter.com/@center_identity" target="_blank">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
          </Anchor>
          <Anchor
            href="https://www.youtube.com/@centeridentity9030"
            target="_blank"
          >
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandYoutube
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
          </Anchor>
          <Anchor
            href="https://www.linkedin.com/company/centeridentity/"
            target="_blank"
          >
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandLinkedin
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
          </Anchor>
        </Group>
      </div>
    </div>
  );
}
